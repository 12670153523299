export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'shipToName',
    label: 'field.name',
    hideToggle: true,
  },
  {
    key: 'shipToAddress',
    label: 'field.address',
    hideToggle: true,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
