<template>
  <b-dropdown class="product-brand-filter-dropdown" variant="light" no-caret right>
    <template #button-content>
      <feather-icon icon="FilterIcon" class="mr-50" />
      {{ $t("button.filter") }}
      <b-badge variant="light-danger" class="ml-50" pill v-if="productBrandId.length">
        {{ productBrandId.length }}
      </b-badge>
    </template>

    <b-dropdown-header>
      <b-row>
        <b-col cols="6">
          {{ $t("field.brand") }}
        </b-col>
        <b-col cols="6" class="text-right">
          <b-link class="text-danger" @click="clearFilter">
            {{ $t("button.clear") }}
          </b-link>
        </b-col>
      </b-row>
    </b-dropdown-header>
    <b-dropdown-form class="product-brand-dropdown-form">
      <b-form-checkbox-group
        :key="`brand-filter-${key}`"
        name="brands"
        v-model="productBrandId"
        @input="onChange"
      >
        <b-form-checkbox
          v-for="item in items"
          :key="`brand-${item.id}`"
          class="mb-1"
          :value="item.id"
        >
          {{ trans(item, "name", $i18n.locale) }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownHeader,
  BDropdownForm,
  BLink,
  BFormCheckboxGroup,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";

const ProductBrandRepository = Repository.get("productBrand");

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownHeader,
    BDropdownForm,
    BLink,
    BFormCheckboxGroup,
    BFormCheckbox,
    BBadge,
  },
  data() {
    return {
      key: 1,
      loading: false,
      items: [],
      productBrandId: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    setDefaultValue() {
      const queryParam = this.$route.query.productBrandId;
      if (typeof queryParam === "string") {
        this.productBrandId = [queryParam];
        this.key++;
      } else if (Array.isArray(queryParam)) {
        this.productBrandId = queryParam;
        this.key++;
      }
    },
    onChange() {
      this.$emit("change", this.productBrandId);
    },
    clearFilter() {
      this.productBrandId = [];
      this.onChange();
    },
    getData() {
      this.loading = true;
      this.updateQuerySting();
      ProductBrandRepository.listAll({
        order: "nameEn",
        sort: "ASC",
        searchFields: [],
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data];
            this.setDefaultValue();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>