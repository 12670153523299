export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'no',
    label: 'field.code',
    hideToggle: true,
  },
  {
    key: 'reasonCodeDes',
    label: 'field.description',
    hideToggle: true,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
