<template>
  <b-modal hide-footer centered :title="title" size="md" v-model="isShow">
    <validation-observer ref="form">
      <n-input :fields="fields" v-model="data"> </n-input>
    </validation-observer>
    <b-row>
      <b-col cols="12" class="text-right">
        <n-button-loading
          type="button"
          variant="primary"
          :loading="loading"
          @click="save"
        >
          {{ $t("button.save") }}
        </n-button-loading>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import NInput from "@/components/NInput";
import NButtonLoading from "@/components/NButtonLoading.vue";
import FormInput from "./formInput";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    NInput,
    NButtonLoading,
  },
  data() {
    return {
      isShow: false,
      loading: false,
      title: "",
      data: {},
    };
  },
  methods: {
    show(cartLine) {
      this.title = `${this.trans(
        cartLine,
        "productName",
        this.$i18n.locale
      )} - ${cartLine.productCode}`;
      this.data = { ...cartLine };
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
      this.loading = false;
    },
    stopLoading() {
      this.loading = false;
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$emit("save", this.data);
        }
      });
    },
  },
  setup() {
    const fields = [...FormInput];

    return {
      fields,
    };
  },
};
</script>