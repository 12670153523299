<template>
  <b-card class="ecommerce-card mb-1" no-body>
    <div class="item-img text-center">
      <b-skeleton-img class="card-img-top product-image" />
    </div>

    <b-card-body class="p-1 pb-0">
      <div class="item-wrapper">
        <h6 class="item-name">
          <b-skeleton width="70%"></b-skeleton>
          <b-skeleton width="30%" class="mb-0"></b-skeleton>
        </h6>
      </div>
    </b-card-body>

    <div class="item-options text-center">
      <b-skeleton type="button" width="100%"></b-skeleton>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BSkeleton, BSkeletonImg } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BSkeleton,
    BSkeletonImg,
  },
};
</script>