<template>
  <b-input-group class="input-group-merge">
    <b-form-input
      class="search-product"
      :placeholder="$t('button.search')"
      :value="value"
      v-debounce:300ms="stopTyping"
      @input="startTyping"
    />
    <b-input-group-append is-text>
      <feather-icon
        v-if="value"
        icon="XIcon"
        class="text-muted cursor-pointer text-danger"
        @click="clearSearch"
      />
      <feather-icon icon="SearchIcon" class="text-muted" v-else />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { BInputGroup, BInputGroupAppend, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    value: {},
  },
  methods: {
    startTyping(value) {
      this.$emit("input", value);
      this.$emit("startTyping");
    },
    stopTyping() {
      this.$emit("serach");
    },
    clearSearch() {
      this.$emit("input", "");
      this.$emit("serach");
    },
  },
  setup() {},
};
</script>