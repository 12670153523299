export default [
  {
    key: 'expectDeliveryDate',
    label: 'field.expectDeliveryDate',
    rules: 'required',
    type: 'date-picker',
    cols: 12,
    disableType: 'before',
  },
]
