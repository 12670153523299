<template>
  <b-modal v-model="isShow" hide-footer centered size="xl">
    <b-row class="mb-1">
      <b-col>
        <label>{{ $t("field.entries") }}</label>
        <v-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block ml-50 mr-1"
        />
      </b-col>
      <b-col cols="6" lg="4">
        <search-input
          v-model="query.search"
          @startTyping="loading = true"
          @serach="search"
        />
      </b-col>
    </b-row>
    <div>
      <n-table
        ref="table"
        :key="`table-${key}`"
        @sort-changed="sortChanged"
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="query.page"
        :per-page="$store.state.pagination.perPage"
        :total="total"
        :resource="resource"
        :route="route"
        :updatable="false"
        :deletable="false"
      >
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              :variant="data.item.id == cart.outletId ? 'secondary' : 'primary'"
              :disabled="data.item.id == cart.outletId"
              pill
              size="sm"
              @click="chooseAddress(data.item)"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>
          </div>
        </template>
      </n-table>
      <n-pagination
        ref="pagination"
        :total="total"
        :per-page="$store.state.pagination.perPage"
        :page="query.page"
        @change="changePage"
      ></n-pagination>
    </div>
  </b-modal>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import NTable from "@/components/NTable";
import NColumnVisibility from "@/components/NColumnVisibility";
import NPagination from "@/components/NPagination";
import TableFields from "./tableFields";
import Repository from "@/repositories/RepositoryFactory";
import SearchInput from "@/components/dp/SearchInput.vue";

const OutletShippingOptionRepository = Repository.get("outletShippingOption");

export default {
  components: {
    BRow,
    BCol,
    BButton,

    vSelect,

    NTable,
    NColumnVisibility,
    NPagination,
    SearchInput,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  data() {
    return {
      key: 1,
      isShow: false,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      query: {},
      items: [],
      total: 0,
      cart: {},
      loading: false,
    };
  },
  methods: {
    show(item) {
      this.cart = { ...item };
      this.query = {
        page: 1,
        order: "id",
        sort: "DESC",
        search: "",
        filter: [
          {
            filtertype: "AND",
            filters: [
              {
                searchby: "outlet_id",
                searchoperator: "=",
                search: this.cart.outletId,
              },
            ],
          },
        ],
      };
      this.isShow = true;
      this.getData();
    },
    hide() {
      this.isShow = false;
    },
    search() {
      this.query.filter = [
        {
          filtertype: "AND",
          filters: [
            {
              searchby: "outlet_id",
              searchoperator: "=",
              search: this.cart.outletId,
            },
          ],
        },
        {
          filtertype: "OR",
          filters: [
            {
              searchby: "ship_to_name",
              searchoperator: "%",
              search: this.query.search,
            },
            {
              searchby: "ship_to_address",
              searchoperator: "%",
              search: this.query.search,
            },
          ],
        },
      ];
      this.getData();
    },
    getData() {
      this.loading = true;
      OutletShippingOptionRepository.index({
        ...this.query,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    chooseAddress(item) {
      this.$emit("save", item.id);
      this.hide();
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "outlet";
    const route = "outlet";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>
