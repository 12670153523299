<template>
  <b-modal hide-footer centered size="md" v-model="isShow">
    <validation-observer ref="form">
      <n-input :fields="fields" v-model="data"> </n-input>
    </validation-observer>
    <b-row>
      <b-col cols="12" class="text-right">
        <n-button-loading
          type="button"
          variant="primary"
          :loading="loading"
          @click="save"
        >
          {{ $t("button.save") }}
        </n-button-loading>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import NInput from "@/components/NInput";
import NButtonLoading from "@/components/NButtonLoading.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    NInput,
    NButtonLoading,
  },
  data() {
    return {
      isShow: false,
      loading: false,
      data: {
        expectDeliveryDate: null,
      },
      fields: [],
    };
  },
  methods: {
    show(item, fields) {
      this.fields = [...fields];
      this.data = { ...item, expectDeliveryDate: item.expectDeliveryDate };
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
      this.loading = false;
    },
    stopLoading() {
      this.loading = false;
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$emit("save", this.data);
        }
      });
    },
  },
};
</script>