<template>
  <b-modal
    v-model="isShow"
    :title="$t('general.choosePromotionFunction')"
    hide-footer
    centered
    size="lg"
  >
    <b-row>
      <b-col cols="auto" class="text-right">
        <b-row align-h="end">
          <b-col class="pl-0" cols="auto">
            <n-column-visibility
              :fields="fields"
              :visible-columns="visibleColumns"
              v-model="visibleColumns"
              @change="key++"
            ></n-column-visibility>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="my-1" align-h="end">
      <b-col cols="6">
        <search-input
          v-model="query.search"
          @startTyping="loading = true"
          @serach="search"
        />
      </b-col>
    </b-row>
    <div>
      <n-table
        ref="table"
        :key="`table-${key}`"
        @sort-changed="sortChanged"
        :visible-columns="visibleColumns"
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="query.page"
        :per-page="$store.state.pagination.perPage"
        :total="total"
        :resource="resource"
        :route="route"
        :updatable="false"
        :deletable="false"
      >
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              variant="primary"
              pill
              size="sm"
              @click="chooseReason(data.item)"
              v-if="data.item.no != cart.promotionFunctionNo"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>
            <b-button
              variant="danger"
              pill
              size="sm"
              @click="removeReason(data.item)"
              v-else
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
        </template>
      </n-table>
      <n-pagination
        ref="pagination"
        :total="total"
        :per-page="$store.state.pagination.perPage"
        :page="query.page"
        @change="changePage"
      ></n-pagination>
    </div>
  </b-modal>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import NTable from "@/components/NTable";
import NColumnVisibility from "@/components/NColumnVisibility";
import NPagination from "@/components/NPagination";
import TableFields from "./tableFields";
import Repository from "@/repositories/RepositoryFactory";
import SearchInput from "@/components/dp/SearchInput.vue";

const SalesReasonCodeRepository = Repository.get("salesReasonCode");

export default {
  components: {
    BRow,
    BCol,
    BButton,

    vSelect,

    NTable,
    NColumnVisibility,
    NPagination,
    SearchInput,
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.salesReasonCode;
      },
      set(value) {
        this.$store.commit("column/SET_SALES_REASON_CODE_COLUMN", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      isShow: false,
      query: {},
      items: [],
      total: 0,
      cart: {},
      loading: false,
    };
  },
  methods: {
    show(item) {
      this.cart = { ...item };
      this.query = {
        limit: 20,
        page: 1,
        order: "no",
        sort: "ASC",
        search: "",
        filter: null,
      };
      this.isShow = true;
      this.getData();
    },
    hide() {
      this.isShow = false;
    },
    search() {
      this.query.filter = {
        filtertype: "OR",
        filters: [
          {
            searchby: "reasonCodeDes",
            searchoperator: "%",
            search: this.query.search,
          },
        ],
      };
      this.getData();
    },
    getData() {
      this.loading = true;
      SalesReasonCodeRepository.index({
        ...this.query,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    chooseReason(item) {
      this.$emit("save", this.cart, item);
      this.hide();
    },
    removeReason(item) {
      this.$emit("save", this.cart, {
        promotionFunctionNo: "",
        promotionFunctionDescription: "",
      });
      this.hide();
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "";
    const route = "";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>
