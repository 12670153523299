<template>
  <b-row no-gutters>
    <b-col>
      <div class="d-flex">
        <b-avatar
          size="60"
          variant="light-primary"
          :src="cart.outletImageUrl"
          :text="avatarText(trans(cart, 'outletName', $i18n.locale))"
        >
          <feather-icon
            v-if="!trans(cart, 'outletName', $i18n.locale)"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
        <div class="px-1">
          {{ trans(cart, "outletName", $i18n.locale) }}
          <small class="d-block text-primary">{{ cart.outletCode }}</small>
          <small class="two-line" v-if="cart.canChangeAddress">
            <b-link class="link-underline" @click="changeAddress">
              {{ trans(cart, "outletAddress", $i18n.locale) }}
            </b-link>
          </small>
          <small class="two-line" v-else>
            {{ trans(cart, "outletAddress", $i18n.locale) }}
          </small>
        </div>
      </div>
    </b-col>
    <b-col cols="1" class="text-right">
      <b-button
        v-b-tooltip.hover
        :title="$t('button.update')"
        variant="link"
        size="sm"
        :disabled="isDisableUpdate"
        class="p-0"
        @click="showOutletModal"
      >
        <feather-icon size="20" icon="EditIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BAvatar, BButton, BLink } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BButton,
    BLink,
  },
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    isDisableUpdate() {
      if (this.cart.salesInvoiceId || this.cart.orderId) {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      avatarText,
    };
  },
  methods: {
    showOutletModal() {
      this.$emit("showOutletModal");
    },
    changeAddress() {
      this.$emit("changeAddress");
    },
  },
};
</script>
