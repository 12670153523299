export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
  },
  {
    key: 'outletCode',
    sortable: true,
    sortField: 'outletCode',
    label: 'field.outletCode',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
  },
  {
    key: 'name',
    label: 'field.outletName',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    hideToggle: true,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
    type: 'tel',
    hideToggle: true,
  },
  {
    key: 'outletSegment',
    label: 'field.outletSegment',
  },
  {
    key: 'address',
    label: 'field.address',
    localization: true,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
